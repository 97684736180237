<template>
  <div @click.stop>
    <div @click="dialog=true">
        <v-chip v-if="(stage === 0)" label small color="info">In progress</v-chip>
        <v-chip v-else-if="(stage === 1)" label small color="warning">Under review</v-chip>
        <v-chip v-else-if="(stage === 2)" label small color="success">Completed</v-chip>
        <v-chip v-else-if="(stage === 3)" label small color="grey lighten-2">Not due</v-chip>
        <v-chip v-else-if="((stage || 0) === 0)" label small color="">Upcoming</v-chip>
    </div>

    <lb-dialog v-model="dialog" heading="View Details" width="400" :loading="loading">
      <template v-slot:body>
        <div>
          <div>
            <div class="subtitle-1 line-1">
              <span v-if="type==='dashboard'">Compliance Dashboard</span>
              <span v-else-if="type==='tds_monthly'">TDS Monthly</span>
              <span v-else-if="type==='tds_quarterly'">TDS Quarterly</span>
              <span v-else-if="type==='gstr_1'">GSTR 1</span>
              <span v-else-if="type==='gstr_3b'">GSTR 3B</span>
              <span v-else-if="type==='refund'">Refund</span>
              <span></span>
            </div>
            <div class="subtitle-2 mb-2">{{item.month}} - {{yeardata.year}}</div>
          </div>
          <div class="d-flex mb-2" v-if="stageshow">
            <div>Status:</div>
            <v-spacer></v-spacer>
            <div>
              <v-menu left offset-y elevation-0 :nudge-width="40" v-if="!viewonly">
                <template v-slot:activator="{ on, attrs }">
                    <div v-bind="attrs" v-on="on">
                      <v-chip small label v-if="(stage === 0)" color="info">In progress <v-icon class="ml-2">mdi-menu-down</v-icon></v-chip>
                      <v-chip small label v-else-if="(stage === 1)" color="warning">Under review <v-icon class="ml-2">mdi-menu-down</v-icon></v-chip>
                      <v-chip small label v-else-if="(stage === 2)" color="success">Completed <v-icon class="ml-2">mdi-menu-down</v-icon></v-chip>
                      <v-chip small label v-else-if="(stage === 3)" color="grey">Not due <v-icon class="ml-2">mdi-menu-down</v-icon></v-chip>
                      <v-chip small label v-else-if="((stage || 0) === 0)" class="">Upcoming <v-icon class="ml-2">mdi-menu-down</v-icon></v-chip>
                    </div>
                </template>
                <v-card >
                    <v-list dense class="pa-0 border-left-default">
                      <v-list-item @click="SelectStage('inprogress',type,(yeardata || {})._id)">
                          <v-list-item-title>
                            <span label small class="info--text">In progress</span>
                          </v-list-item-title>
                      </v-list-item>
                      <v-list-item @click="SelectStage('underreview',type,(yeardata || {})._id)">
                          <v-list-item-title>
                            <span label small class="warning--text">Under review</span>
                          </v-list-item-title>
                      </v-list-item>
                      <v-list-item @click="SelectStage('completed',type,(yeardata || {})._id)">
                          <v-list-item-title>
                            <span  label small class="success--text">Completed</span>
                          </v-list-item-title>
                      </v-list-item>
                      <v-list-item @click="SelectStage('notdue',type,(yeardata || {})._id)">
                          <v-list-item-title>
                            <span  label small class="grey--text">Not due</span>
                          </v-list-item-title>
                      </v-list-item>
                    </v-list>
                </v-card>
              </v-menu>
              <div v-else>
                <v-chip small label v-if="(stage === 0)" color="info">In progress </v-chip>
                <v-chip small label v-else-if="(stage === 1)" color="warning">Under review</v-chip>
                <v-chip small label v-else-if="(stage === 2)" color="success">Completed</v-chip>
                <v-chip small label v-else-if="(stage === 3)" color="grey">Not due</v-chip>
                <v-chip small label v-else-if="((stage || 0) === 0)" color="">Upcoming</v-chip>
              </div>
            </div>
          </div>
          <div class="d-flex align-center" v-if="stage !== 3">
            <div>Final file:</div>
            <v-icon small v-if="file" color="success" class="ml-2">mdi-check-circle</v-icon>
            <v-icon small v-else color="info" class="ml-2">mdi-timer-sand</v-icon>
            <v-tooltip bottom content-class="tooltip-bottom" v-if="file">
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" class="shadow-off" icon @click="$nova.downloadFile(file)"><v-icon>mdi-download</v-icon></v-btn>
              </template>
              <span>Download</span>
            </v-tooltip>
            <v-spacer></v-spacer>
            <div class="ml-2" v-if="!viewonly">
              <lb-file :hidedetails="true" :drag="false" v-model="filesdata" accept=".csv,.xlsx,.xls,.xlsm,.xlsb" label="" :displayname="true" @change="fileuploade((yeardata || {})._id)"/>
            </div>
          </div>
        </div>
      </template>
      <!-- <template v-slot:actions>
        <v-spacer></v-spacer>
        <v-btn
          small
          color="primary"
          v-if="editId"
          @click="updateRights()"
          >Update</v-btn
        >
        <v-btn small color="primary" @click="saveRights()" v-else
          >Create</v-btn
        >
      </template> -->
    </lb-dialog>
    
    <!-- <v-menu left offset-x  elevation-0  :close-on-content-click="false" :disabled="menudisable">
        <template v-slot:activator="{ on, attrs }">
          <div v-bind="attrs" v-on="on">
              <v-chip v-if="(stage === 0)" label small color="info">In progress</v-chip>
              <v-chip v-else-if="(stage === 1)" label small color="warning">Under review</v-chip>
              <v-chip v-else-if="(stage === 2)" label small color="success">Completed</v-chip>
              <v-chip v-else-if="(stage === 3)" label small color="grey lighten-2">Not due</v-chip>
              <v-chip v-else-if="((stage || 0) === 0)" label small color="">Upcoming</v-chip>
          </div>
        </template>
        <v-card>
          <v-card-text>
              <div>
                <div class="d-flex" >
                    <v-menu left offset-y elevation-0 :nudge-width="40" v-if="stageshow" >
                      <template v-slot:activator="{ on, attrs }">
                          <div v-bind="attrs" v-on="on">
                            <v-chip outlined small label >
                              <span v-if="(stage === 0)" class="info--text">In progress </span>
                              <span v-else-if="(stage === 1)" class="warning--text">Under review</span>
                              <span v-else-if="(stage === 2)" class="success--text">Completed</span>
                              <span v-else-if="(stage === 3)" class="grey--text">Not due</span>
                              <span v-else-if="((stage || 0) === 0)" class="">Upcoming</span>
                              <v-icon right>mdi-menu-down</v-icon>
                            </v-chip>
                          </div>
                      </template>
                      <v-card >
                          <v-list dense class="pa-0 border-left-default">
                            <v-list-item @click="SelectStage('inprogress',type,(yeardata || {})._id)">
                                <v-list-item-title>
                                  <span label small class="info--text">In progress</span>
                                </v-list-item-title>
                            </v-list-item>
                            <v-list-item @click="SelectStage('underreview',type,(yeardata || {})._id)">
                                <v-list-item-title>
                                  <span label small class="warning--text">Under review</span>
                                </v-list-item-title>
                            </v-list-item>
                            <v-list-item @click="SelectStage('completed',type,(yeardata || {})._id)">
                                <v-list-item-title>
                                  <span  label small class="success--text">Completed</span>
                                </v-list-item-title>
                            </v-list-item>
                            <v-list-item @click="SelectStage('notdue',type,(yeardata || {})._id)">
                                <v-list-item-title>
                                  <span  label small class="grey--text">Not due</span>
                                </v-list-item-title>
                            </v-list-item>
                          </v-list>
                      </v-card>
                    </v-menu>
                    <v-spacer></v-spacer>
                    <div class="ml-2" v-if="fileupload">
                      <lb-file :hidedetails="true" :drag="false" v-model="filesdata" accept=".csv,.xlsx,.xls,.xlsm,.xlsb" label="" :displayname="false" @change="fileuploade(filesdata,(yeardata || {})._id)"/>
                    </div>
                </div>
                <div class="align-center mt-2" v-if="fileupload">
                    <span  @click="$nova.downloadFile()" class="mx-2 cursor-pointer blue--text text--darken-2 caption">
                      <v-icon class="mr-1 blue--text text--darken-2">mdi-file</v-icon>
                      filename
                    </span>
                </div>
              </div>
          </v-card-text>
        </v-card>
    </v-menu> -->
  </div>
</template>

<script>
export default {
  data: function() {
    return {
      loading: false,
      loglist: [],
      filesdata:null,
      fileuploadpercent: {},
      filecount: 0,
      fileUpDetil: {},
      fileUploadedDetilas: {},
      fileuploading: false,
      dialog: false
    };
  },
  // created() {
  //   this.refreshData();
  // },
  // activated() {
  //   this.refreshData();
  // },
  props: {
    yeardata: {
      type: Object,
      default: () => {},
    },
    item: {
      type: Object,
      default: () => {},
    },
    type: {
      type: String,
      default: "",
    },
    file: {
      type: String,
      default: "",
    },
    stage: {
      type: Number,
      default: undefined,
    },
    stageshow:{
      type:Boolean,
      default:true,
    },
    viewonly:{
      type:Boolean,
      default:true,
    },
    // viewanalysis:{
    //   type:Boolean,
    //   default:false,
    // },
    menudisable:{
      type:Boolean,
      default:false,
    }
  },
  methods:{
    SelectStage(status,type,id) {
      console.log(status,type,id);
      let object = { field:type, value:status,key:"stage"};
      let url = '/v2/tds/monthly/changestage/'+ id
      this.loading = true;
        this.axios.post(url, {data:object}).then(dt => {
          if(dt.data.status === "success"){
            this.$store.commit("sbSuccess", "Status Changed");
            this.$emit('message-success');
          }else throw new Error ("Error Select Stage");
        }).catch(err => {
          this.$store.commit("sbError", err.message || err || "Unknown error!");
          console.log(err);
        }).finally(() => {
          this.loading = false;
        })
    },
    fileuploade(id){
      let file = this.filesdata;
      if (file?.name) {
        // console.log(file,id);
        let storeapi = '/v2/tds/monthly/storesummaryfile/'+ id
         this.loading = true;
        // let success = false;
        // console.log("called 1");
        if(this.fileuploading === false){
          Promise.resolve().then(() => {
            this.fileuploading = true;
            // console.log("called 2");
            return this.$nova.uploadFile(this.axios, file, {
              progressfunction: (e) => {
                let p = (e.loaded * 100) / e.total;
                this.fileuploadpercent[id] = p.toFixed(0);
                this.filecount++;
              },
            })
          }).then((dt) => {
            if (dt.data.status === "success") {
                this.fileUpDetil = dt.data.data[0];
              return this.axios.post(storeapi,{ data: { upload: this.fileUpDetil._id, field: this.type} });
            } else throw new Error("Unable to upload file");
          })
          .then((dt) => {
            if (dt.data.status === "success") {
              this.fileUploadedDetilas = dt.data.data;
              this.$store.commit("sbSuccess", "File Uploaded");
            } else throw new Error(dt.data.message || "Error storing file");
          })
          .catch((err) => {
            //this.fileproerr[id] = err.message || err || "Unknown error!";
            this.$store.commit("sbError", err.message || err || "Unknown error!");
            console.log(err);
          })
          .finally(() => {
            this.loading = false;
            this.fileuploading = false;
            this.filesdata = null;
            // console.log("ss");
          });
        }
      }
    },
  }
};
</script>

<style scoped>
</style>

